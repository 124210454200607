import React, { useState ,useEffect,useRef} from "react";

// import car from "../../images/car_sedan.png";
import car from "../../images/ic_car_variants.png"
import axios from "axios";

const OTAUpdate = () => {
  const [progress, setProgress] = useState(0); // State for progress
  const [updating, setUpdating] = useState(false); // State for update process
  const [status, setStatus] = useState("Needs Upgrade");
  const [isToggled, setIsToggled] = useState(false);
  const intervalRef = useRef(null); 
  const [statusResponse, setStatusResponse] = useState(null); // Store the `get_status` response
  const [triggerResponse, setTriggerResponse] = useState(null); // Store the `trigger_action` response
 

  // Function to call when toggled ON
  const handleToggleOn = () => {
    console.log("Toggle is ON");
    // Add your ON functionality here
  };

  // Function to call when toggled OFF
  const handleToggleOff = () => {
    console.log("Toggle is OFF");
    // Add your OFF functionality here
  };
  const handleToggle = () => {
    setIsToggled(!isToggled);

    if (!isToggled) {
      handleToggleOn(); // Call ON function
      
    } else {
      handleToggleOff(); // Call OFF function
      //local testing
      stopStatusInterval()
    }
  };

  // Simulates the progress update
  const handleStartUpdate = () => {
    if(isToggled)
    {
    sendMessageToServer();
    //get order status from websocket
    getOrderStatus();
      //update the progress and status when sinclair is ON
      if (updating) return;
    
      // setStatus("Initiated"); // Start lifecycle
      // setUpdating(true);
      // let interval = setInterval(() => {
      //   setProgress((prev) => {
      //     if (prev >= 100) {
      //       clearInterval(interval); // Stop when progress reaches 100%
            
      //       setStatus("Updated Successfully"); // Update status on completion
      //       setTimeout(() => {
      //         setProgress(0)
      //         setUpdating(false); // Enable the Start button after a delay
      //         setStatus("Need Upgrade"); // Optional status message
             
      //       }, 7000); // 7-second delay to re-enable button
         
      //       return 100;
      //     }
      //     // return prev + 10; // Increment progress
      //     if (prev >= 20) {
      //         setStatus("Downloading"); // Midway update
      //     }
      //     if (prev >= 40) {
      //         setStatus("Downloaded"); // Midway update
      //     }
      //     if (prev >= 60) {
      //         setStatus("Installing"); // Midway update
      //     }
      //     return prev + 10;
      //   });
      // }, 3000); // Adjust interval time (500ms for demo)
    }
    else{
    if (updating) return;

    setStatus("Initiated"); // Start lifecycle
    setUpdating(true);
    let interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(interval); // Stop when progress reaches 100%
          
          setStatus("Updated Successfully"); // Update status on completion
          setTimeout(() => {
            setProgress(0)
            setUpdating(false); // Enable the Start button after a delay
            setStatus("Need Upgrade"); // Optional status message
           
          }, 7000); // 7-second delay to re-enable button
       
          return 100;
        }
        // return prev + 10; // Increment progress
        if (prev >= 20) {
            setStatus("Downloading"); // Midway update
        }
        if (prev >= 40) {
            setStatus("Downloaded"); // Midway update
        }
        if (prev >= 60) {
            setStatus("Installing"); // Midway update
        }
        return prev + 10;
      });
    }, 3000); // Adjust interval time (500ms for demo)
  }
  };

  // Resets progress to zero
  const handleCancelUpdate = () => {
    setProgress(0); // Reset progress
    setUpdating(false); // Stop the update process
    setStatus("Needs Upgrade"); // Reset status
  };
  const [ws, setWs] = useState(null); // WebSocket connection
  const [message, setMessage] = useState(""); // Message sent to the server

  useEffect(() => {
    // Establish WebSocket connection on component mount
    // const socket = new WebSocket("ws://localhost:8085");
    const socket = new WebSocket("ws://localhost:8765");


    socket.onopen = () => {
      console.log("WebSocket connection established");
    };

    socket.onmessage = (event) => {
      console.log("Received message from server:", event.data);
      //to get the status 
      try {
        const data = JSON.parse(event.data); // Parse the JSON response from the server
    
        if (data.status === "complete" || data.status === "completed") {
          console.log("Status is complete or completed. Stopping the interval.");
          setStatus(data.status)
          stopStatusInterval(); // Call the method to stop the interval
          setProgress(100)


          setTimeout(() => {
            setProgress(0)
            setUpdating(false); // Enable the Start button after a delay
            setStatus("Need Upgrade"); // Optional status message
           
          }, 7000); // 7-second delay to re-enable button
       
        } else {
          console.log(`Current status: ${data.status}`);
          setStatus(data.status)

          //set progress values
          if (data.status === "booked" || data.status === "Booked") {
          setProgress(30)
          }
          if (data.status === "scheduled" || data.status === "Scheduled")
          {
            setProgress(70)
          }

          if (data.status === "errored" || data.status === "cancelled" || data.status === "Errored" || data.status === "Cancelled") {
            //stop sinclair flow
            stopStatusInterval()
          }
          
        }
      } catch (error) {
        console.error("Error parsing JSON response:", event.data);
      }

      try {
        const data = JSON.parse(event.data); // Assuming server sends JSON
        if (data.action === "get_status") {
          setStatusResponse(data.response);
          console.log("WebSocket connection established",data.response);
          const responseObj = JSON.parse(data.response); 
          if (responseObj.status === "complete" || responseObj.status === "completed") {
            console.log("Status is complete or completed. Stopping interval.");
            stopStatusInterval(); // Stop the interval when the status is complete or completed
          }
        } else if (data.action === "trigger_action") {
          setTriggerResponse(data.response);
        }
      } catch (err) {
        console.error("Error parsing server response:", event.data);
      }
    };

    socket.onclose = () => {
      console.log("WebSocket connection closed");
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    // Store WebSocket connection in state
    setWs(socket);

    // Clean up WebSocket connection on component unmount
    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, []);
  const sendMessageToServer = () => {
    setUpdating(true);
    if (ws && ws.readyState === WebSocket.OPEN) {
      const triggerMessage = "trigger_action"; // Custom message for the server
      ws.send(triggerMessage); // Send message to the Python WebSocket server
      console.log("Sent trigger message to the server");
    } else {
      console.error("WebSocket is not open.");
    }
  };

   // Function to send `get_status` to the server at regular intervals
   const getOrderStatus = () => {
    if (!intervalRef.current && ws && ws.readyState === WebSocket.OPEN) {
      intervalRef.current = setInterval(() => {
        // const message = JSON.stringify({ action: "get_status" }); // Message format
        const message = "get_status"; 
        console.log(`Sending message: ${message}`);
        ws.send(message);
      }, 5000); // Time interval (5000ms = 5 seconds)
    }
    // if (ws && ws.readyState === WebSocket.OPEN) {
    //   const triggerMessage = "get_status"; // Custom message for the server
    //   ws.send(triggerMessage); // Send message to the Python WebSocket server
    //   console.log("Sent getStatus message to the server");
    // } else {
    //   console.error("WebSocket is not open.");
    // }

  };

  // Function to stop sending `get_status`
  const stopStatusInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };
  return (
    <div>
    <button onClick={handleToggle} style={{marginLeft:"20px",fontFamily: "Open Sans"}} disabled={updating}>
    {isToggled ? "Sinclair Update ON" : "Sinclair Update OFF"}
    </button>
  
    <div style={styles.container}>
      {/* <div style={styles.iconContainer}>
        <div style={styles.carIcon}></div>
        
      </div> */}
      <img src={car} ></img>
      {/* <h2 style={styles.title}>OVER-THE-AIR</h2> */}
      <h2 style={styles.title}>{status}</h2>
      <div style={styles.progressBar}>
        <div style={{ ...styles.progress, width: `${progress}%` }}></div>
      </div>
      <div style={styles.dots}>
        {/* {[...Array(5)].map((_, index) => ( */}
        {[...Array(5)].map((_, index) => (
          <div
            key={index}
            style={{
              ...styles.dot,
            //   backgroundColor: progress / 20 > index ? "#87CEEB" : "#E0E0E0",
            // backgroundColor: progress / 20 > index ? "#4CAF50" : "#E0E0E0",
            backgroundColor: progress / 20 > index ? "#4CAF50" : "#E0E0E0",
            }}
          ></div>
        ))}
      </div>
      <div 
      style={styles.buttonContainer}>
        <button onClick={handleStartUpdate} 
        // style={styles.startButton}
        style={{
            ...styles.startButton,
            backgroundColor: updating ? "#B0E0E6" : "#92BB45", // Visual feedback
            cursor: updating ? "not-allowed" : "pointer",
          }}
          disabled={updating} // Disable button while updating
        >
          START UPDATE
        </button>
        {/* <button
        //  disabled={updating}
          style={styles.cancelButton} onClick={handleCancelUpdate} >
          CANCEL
        </button> */}
      </div>
    </div>
    </div>
  );
};

// Inline styles
const styles = {
  container: {
    fontFamily: "Arial, sans-serif",
    textAlign: "center",
    padding: "20px",
    // backgroundColor: "#F8F8F8",
    backgroundColor: "#252525",
    borderRadius: "8px",
    // width: "300px",
    width: "30vw",
    margin: "auto",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
  },
  iconContainer: {
    margin: "10px auto",
    height: "50px",
    width: "50px",
    backgroundColor: "#D3EFFF",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  carIcon: {
    // width: "30px",
    // height: "15px",
    // backgroundColor: "#87CEEB",
    // borderRadius: "5px",
    width: "150px",
    height: "50px",
    backgroundImage: `url(${car})`,
    // backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  title: {
    fontSize: "18px",
    margin: "10px 0",
    // color: "#333",
    color:"white",
    fontFamily: "Open Sans"
  },
  progressBar: {
    height: "10px",
    width: "100%",
    backgroundColor: "#E0E0E0",
    borderRadius: "5px",
    overflow: "hidden",
    margin: "15px 0",
  },
  progress: {
    height: "100%",
    // backgroundColor: "#87CEEB",
    backgroundColor:"#4CAF50",
    transition: "width 0.5s",
  },
  dots: {
    display: "flex",
    justifyContent: "space-around",
    margin: "15px 0",
  },
  dot: {
    height: "12px",
    width: "12px",
    borderRadius: "50%",
    // transition: "background-color 0.3s",
     transition: "background-color 0.3s ease"
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: "20px",
  },
  startButton: {
    padding: "10px 20px",
    // backgroundColor: "#87CEEB",
    backgroundColor:"#92BB45",
    border: "none",
    borderRadius: "5px",
    color: "white",
    cursor: "pointer",
    fontWeight: "bold",
    fontFamily: "Open Sans"
  },
  cancelButton: {
    padding: "10px 20px",
    backgroundColor: "#E0E0E0",
    border: "none",
    borderRadius: "5px",
    color: "#666",
    cursor: "pointer",
    // cursor: "not-allowed",
  },
};

export default OTAUpdate;
