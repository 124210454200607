import React from 'react';
import { FaRedoAlt } from 'react-icons/fa'; // Importing the refresh icon from react-icons
import './RefreshFAB.css'; // Import the CSS for FAB styling

// This component renders the floating action button and accepts an `onClick` prop
const RefreshFAB = ({ onClick }) => {
  return (
    <div className="fab-container">
      <button className="fab" onClick={onClick}>
        <FaRedoAlt size={24} />
      </button>
    </div>
  );
};

export default RefreshFAB;
