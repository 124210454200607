import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createHashRouter, RouterProvider } from "react-router-dom";
import AutomotiveVideoPlayer from "./components/VideoPlayer/AutomotiveVideoPlayer";
import LoginContextProvider from "./store/LoginContextProvider";
import OtaDetails from "./pages/ota-details/OtaDetails";
import HealthCheckPortal from "./pages/HealthCheckPortal/HealthCheckPortal";  // Import HealthCheckPortal
import DMSReportDownloader from "./pages/HealthCheckPortal/DMSReportDownloader";
import CodebeamerReportDownloader from "./pages/HealthCheckPortal/CodebeamerReportDownloader";
import ChapterOne from "./pages/dashboard/ChapterOne";
import ChapterTwo from "./pages/dashboard/ChapterTwo";
import Sidebar from "./components/SideNavBar/SideNavBar";
import DocumentLoaderWindow from "./pages/artifacts/DocumentLoaderWindow";
import DocumentLoaderWindowSDV from "./pages/artifacts/DocumentLoaderWindowSDV";
import ClaimAnalysis from "./pages/claim-analysis/ClaimAnalysis";
import SDVOperateDashboard from "./pages/dashboard/SdvOperateDashboard";
import ProtectedRoute from "./pages/ProtectedRoute";
import Ontology from "./pages/ontology/Ontology";

const router = createHashRouter([
  {
    path: "/",
    element: <App />,
  },
  { path: "persona/chapter-one", element: <ChapterOne /> },
  { path: "persona/chapter-two", element: <ChapterTwo /> },
  { path: "persona/chapter-three", element: <SDVOperateDashboard /> },
  { path: "persona/automotive-video-player", element: <AutomotiveVideoPlayer /> },
  { path: "persona/artifact", element: <DocumentLoaderWindow /> },
  {
    path: "persona/artifact-sdv",
    element: <DocumentLoaderWindowSDV />,
  },
  {
    path: "persona/ota-details",
    element: <OtaDetails />,
  },
  {
    path: "persona/health-check-portal",  // New path for HealthCheckPortal
    element: <HealthCheckPortal />,      // Link it to the HealthCheckPortal component
  },
  {
    path: "persona/dms-report-downloader",  // New path for HealthCheckPortal
    element: <DMSReportDownloader />,      // Link it to the DMS reports portal component
  },
  {
    path: "persona/codebeamer-report-downloader",  // New path for HealthCheckPortal
    element: <CodebeamerReportDownloader />,      // Link it to the DMS reports portal component
  },
  {
    path: "persona/ontology",
    element: <Ontology />,
  },
  {
    path: "navbar",
    element: <Sidebar />,
  },
  {
    path: "claim-analysis",
    element: <ClaimAnalysis />,
  },
  {
    element: <ProtectedRoute />,
    children: [
      { path: "persona/chapter-one", element: <ChapterOne /> },
      { path: "persona/chapter-two", element: <ChapterTwo /> },
      { path: "persona/chapter-three", element: <SDVOperateDashboard /> },
      { path: "persona/automotive-video-player", element: <AutomotiveVideoPlayer /> },
      { path: "persona/artifact", element: <DocumentLoaderWindow /> },
      {
        path: "persona/artifact-sdv",
        element: <DocumentLoaderWindowSDV />,
      },
      {
        path: "persona/ota-details",
        element: <OtaDetails />,
      },
      {
        path: "persona/ontology",
        element: <Ontology />,
      },
      {
        path: "navbar",
        element: <Sidebar />,
      },
      {
        path: "claim-analysis",
        element: <ClaimAnalysis />,
      },
      {
        path: "*/*",
        redirect: "/",
      },
    ],
  },
]);

const domain = "dev-kfsxic63qu1dlvsz.us.auth0.com";
const clientId = "RyHUp3yXX34kk1xF7BddGsKuOxepcoQU";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <LoginContextProvider>
      <RouterProvider router={router} />
    </LoginContextProvider>
  </React.StrictMode>
);

reportWebVitals("Report Data", console.log);
