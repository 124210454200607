import React, { useState, useEffect } from 'react';
import axios from 'axios'; 
import './HealthCheckPortal.css';
import StatusOverview from '../../components/HealthCheckPortalComps/StatusOverview';
import RefreshFAB from '../../components/HealthCheckPortalComps/RefreshFAB';
import Sidebar from "../../components/SideNavBar/SideNavBar";
import TopBar from "../../components/TopBar/TopBar";
import Cookies from 'js-cookie';



const BouncingLoader = () => (
  <div className="bouncing-loader">
    <div></div>
    <div></div>
    <div></div>
  </div>
);



const applicationData = {
  clientId: "123",
  endpoint: "wss://websocket.backend.sdvhub.deloitte.com",
  topic: "clients/1KGEJ6605A5A354BA/+/status" // Example topic
};

const apiUrl = 'https://hviedjw0hb.execute-api.us-east-1.amazonaws.com/production/infra-start-stop';
const oemGetUrl = 'https://sdv-alb-2074293303.us-east-1.elb.amazonaws.com/rag/retrieve/oem';
const diagnosticGetUrl = 'https://sdv-alb-2074293303.us-east-1.elb.amazonaws.com/rag/retrieve/diagnostic';
const oemPostUrl = 'https://sdv-alb-2074293303.us-east-1.elb.amazonaws.com/rag/oem';
const diagnosticPostUrl = 'https://sdv-alb-2074293303.us-east-1.elb.amazonaws.com/rag/diagnostic';
const resetUrl = 'https://sdv-alb-2074293303.us-east-1.elb.amazonaws.com/rag/reset';
const uploadUrl = 'https://sdv-alb-2074293303.us-east-1.elb.amazonaws.com/rag/upload-pdf';

async function callAPI(payload, url) {
  try {
    
    const response = await axios.post(url, payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    
    return response;
  } catch (error) {
    console.error('Error making API call:', error);
    return null;
  }
}


async function callAPICI(payload) {
  try {
    
    const response = await axios.post(apiUrl, payload);
   
    return response;
  } catch (error) {
    console.error('Error making API call:', error);
    return null;
  }
}

async function getData(url) {
  try {
    
    const response = await axios.get(url);
    
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return null;
  }
}

function App() {
  const [statusData, setStatusData] = useState({
    ti: { status: {}, timestamp: null },
    orin: { status: {}, timestamp: null },
    imx8: { status: {}, timestamp: null },
    goldbox: { status: {}, timestamp: null }
  });


  const [isAdmin, setIsAdmin] = useState(false); // State to track if the user is an admin
  const [error, setError] = useState(null); // State to track errors



  const [isUploading, setIsUploading] = useState(false); // Add this line
  const [isResetting, setIsResetting] = useState(false); // Add this line


  const [selectedVIN, setSelectedVIN] = useState('1KGEJ6605A5A354BA');
  const [isLoading, setIsLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [socket, setSocket] = useState(null);
  const [deviceStatuses, setDeviceStatuses] = useState({}); // Track online/offline status of each device

  const [selectedFile, setSelectedFile] = useState(null);
  const [fileMessage, setFileMessage] = useState('');
  const [textBoxValue1, setTextBoxValue1] = useState('');
  const [textBoxValue2, setTextBoxValue2] = useState('');

  // Define state variables for instance details
  const [applicationInstances, setApplicationInstances] = useState({});
  const [simulatorInstances, setSimulatorInstances] = useState({});
  const [metalInstances, setMetalInstances] = useState({});

  const handleVINChange = (event) => {
    const newVIN = event.target.value;
    console.log(`Switching to VIN: ${newVIN}`);
    // Reset the statusData when VIN changes
    setStatusData({
      ti: { status: {}, timestamp: null },
      orin: { status: {}, timestamp: null },
      imx8: { status: {}, timestamp: null },
      goldbox: { status: {}, timestamp: null }
    });
    setSelectedVIN(newVIN);
  };

  const handleRefresh = async () => {
    console.log('Refreshing all devices status...');
    setIsLoading(true);
    try {
      const VIN = selectedVIN;
      await fetch('https://hviedjw0hb.execute-api.us-east-1.amazonaws.com/production/deviceTrigger', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ VIN, command: "status" })
      });
      console.log('Refresh request sent successfully');
    } catch (error) {
      console.error('Error refreshing device status:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRestart = async (device, serviceName) => {
    console.log(`Sending restart trigger for ${device} - ${serviceName}`);
    // Construct the payload for restart command
    const payload = {
      VIN: selectedVIN,
      device: device,
      service: serviceName,  // Ensure this is the correct service name
      command: 'restart',
    };
    // Send the restart trigger request
    try {
      const response = await fetch('https://hviedjw0hb.execute-api.us-east-1.amazonaws.com/production/deviceTrigger', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      if (response.status === 200) {
        console.log(`Restart trigger sent successfully for ${device} - ${serviceName}`);
      } else {
        console.error(`Failed to send restart trigger for ${device} - ${serviceName}. Status Code: ${response.status}`);
      }
    } catch (error) {
      console.error('Error triggering restart:', error);
    }
  };

  const handleToggleState = async (payload) => {
    // Log the payload being sent for toggle action
    console.log('Toggle State Payload:', payload);
    // Send the toggle trigger request
    try {
      const response = await fetch('https://hviedjw0hb.execute-api.us-east-1.amazonaws.com/production/deviceTrigger', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      if (response.status === 200) {
        console.log(`Toggle state trigger sent successfully for ${payload.device} - ${payload.service}`);
      } else {
        console.error(`Failed to send toggle state trigger for ${payload.device} - ${payload.service}. Status Code: ${response.status}`);
      }
    } catch (error) {
      console.error('Error triggering toggle state:', error);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'application/pdf') {
      setSelectedFile(file);
      setFileMessage(`File chosen: ${file.name}`);
    } else {
      alert('Please upload a PDF file.');
    }
  };

  const handleFileUpload = async () => {
    if (selectedFile) {
      setIsUploading(true); // Show loader
      const formData = new FormData();
      formData.append('file', selectedFile);
      try {
        const response = await axios.post(uploadUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
       
        alert('File uploaded successfully');
        setFileMessage(''); // Clear the file message
        setSelectedFile(null); // Clear the selected file
      } catch (error) {
        console.error('Error uploading file:', error);
        alert('Error uploading file');
      }
      finally {
        setIsUploading(false);  // Hide loader
      }
    } else {
      alert('No file selected.');
    }
  };

  const handleTextBoxChange1 = (event) => {
    setTextBoxValue1(event.target.value);
  };

  const handleTextBoxChange2 = (event) => {
    setTextBoxValue2(event.target.value);
  };

  const handleOEMSubmit = async () => {
    const payload = {
      oem: textBoxValue1
    };
    await callAPI(payload, oemPostUrl);
    setTextBoxValue1(''); // Clear the text box
    setTimeout(async () => {
      const oemData = await getData(oemGetUrl);
      setTextBoxValue1(oemData ? oemData.oem : '');
    }, 3000); // 2-second delay
  };

  const handleDiagnosticSubmit = async () => {
    const payload = {
      diagnostic: textBoxValue2
    };
    await callAPI(payload, diagnosticPostUrl);
    setTextBoxValue2('');
    setTimeout(async () => {
      const diagnosticData = await getData(diagnosticGetUrl);
      setTextBoxValue2(diagnosticData ? diagnosticData.diagnostic : '');
    }, 3000); // 2-second delay
  };

  const handleReset = async () => {
    setIsResetting(true);
    await callAPI({}, resetUrl);
    setTimeout(async () => {
      const oemData = await getData(oemGetUrl);
      const diagnosticData = await getData(diagnosticGetUrl);
      setTextBoxValue1(oemData ? oemData.oem : '');
      setTextBoxValue2(diagnosticData ? diagnosticData.diagnostic : '');
      setIsResetting(false); 
    }, 3000); 
  };


  async function controlInstances(type, command) {
    let payload = {};
    switch (type) {
      case 'Application':
        payload = { Type: 'Application', Command: command };
        break;
      case 'Simulator':
        payload = { Type: 'Simulator', Command: command };
        break;
      case 'Metal':
        payload = { Type: 'Metal', Command: command };
        break;
      default:
        console.error('Unknown type:', type);
        return;
    }
    
    const apiResponse = await callAPICI(payload);
    if (apiResponse && apiResponse.status === 200) {
     
      alert(`${command} ${type} successfully`);
    } else {
     
      alert(`Error ${command} ${type}`);
    }
  }





  const fetchUserMail = async () => {
    try {
      const csrfToken = Cookies.get('csrftoken');
      const sessionId = Cookies.get('sessionid');
      const response = await axios.get(
        'https://sdvexperience-backend.deloitte.com/sdv/getMail/',
        {
          headers: {
            'X-CSRFToken': csrfToken,
          },
          withCredentials: true, // if you need to send cookies with the request
        }
      );
     
      const email = response.data['mail'];
     
      checkIfAdmin(email);
    } catch (error) {
      setError(error.message);
      console.error('There was an error fetching the mail', error.message);
    }
  };



  async function checkIfAdmin(email) {
    const url = "https://sdvexperience-backend.deloitte.com/sdv/checkIfAdmin/";

    // Create the payload as raw JSON
    const data = {
        email: email
    };

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
            redirect: 'manual'  // Manually handle redirects
        });

        // If the response status is 302 (redirect), handle it manually
        if (response.status === 302) {
            const redirectUrl = response.headers.get('Location');
           
            // You can now send another request to the redirect URL, if needed
        } else if (response.ok) {
            const result = await response.json();
          
            if (result.status === "true") {
              setIsAdmin(true);
            } else {
              setIsAdmin(false);
            }
            return result;  // Return the response as a JavaScript object
            
        } else {
            return { error: `Request failed` };
        }
    } catch (error) {
        return { error: `An error occurred` };
    }
}

  useEffect(() => {
   
    fetchUserMail();
  }, []);




  useEffect(() => {
    const fetchData = async () => {
      const oemData = await getData(oemGetUrl);
      const diagnosticData = await getData(diagnosticGetUrl);
      setTextBoxValue1(oemData ? oemData.oem : '');
      setTextBoxValue2(diagnosticData ? diagnosticData.diagnostic : '');
    };
    fetchData();
  }, []);

  useEffect(() => {
    // WebSocket connection with retry mechanism
    const connectWebSocket = () => {
      const ws = new WebSocket(applicationData.endpoint);
      ws.onopen = () => {
        console.log("Connected to WebSocket server!");
        setSocket(ws);
        // After WebSocket is connected, send a refresh trigger for the selected VIN
        console.log(`Sending refresh trigger for VIN: ${selectedVIN}`);
        handleRefresh();
      };
      ws.onclose = (e) => {
        console.error("WebSocket closed unexpectedly, reconnecting...", e);
        setTimeout(connectWebSocket, 3000); // Retry after 3 seconds
      };
      ws.onerror = (e) => {
        console.error("WebSocket error:", e);
        ws.close();
      };
      ws.onmessage = (event) => {
        try {
          const message = JSON.parse(event.data);
          console.log("Received message from server:", message);
          // Check if the message is for the goldbox and contains OTA data
          if (message.hardware === "goldbox") {
            const status = message.status;
            // Only keep status for the specific services: carla, cluster, dms, navigation, stereo, stm
            const validServices = ['carla', 'cluster', 'dms', 'navigation', 'stereo', 'stm'];
            const filteredStatus = {};
            // Iterate through the status keys and filter out non-relevant services
            Object.keys(status).forEach(key => {
              if (validServices.includes(key)) {
                filteredStatus[key] = status[key]; // Keep the valid services
              }
            });
            console.log("Filtered Goldbox status:", filteredStatus);
            // Update status data with the filtered status
            setStatusData((prevStatusData) => {
              const updatedStatusData = { ...prevStatusData };
              updatedStatusData['goldbox'] = {
                status: filteredStatus,
                timestamp: message.timestamp
              };
              return updatedStatusData;
            });
            // Mark device as online (because we received status data)
            setDeviceStatuses((prevStatuses) => ({
              ...prevStatuses,
              ['goldbox']: true
            }));
            return; // No need to process further data
          }
          // For other devices, process as usual
          setStatusData((prevStatusData) => {
            const updatedStatusData = { ...prevStatusData };
            updatedStatusData[message.hardware] = {
              status: message.status,
              timestamp: message.timestamp
            };
            // Mark device as online
            setDeviceStatuses((prevStatuses) => ({
              ...prevStatuses,
              [message.hardware]: true
            }));
            return updatedStatusData;
          });
        } catch (error) {
          console.error('Error parsing WebSocket message:', error);
        }
      };
    };
    connectWebSocket(); // Initially connect to WebSocket
    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [selectedVIN]); // Reconnect on VIN change

  // Fetch instance details on component mount
  useEffect(() => {
    const fetchInstanceDetails = async () => {
      const fetchInstanceStatus = async (type) => {
        const payload = { Type: type, Command: 'Status' };
        try {
          const response = await axios.post('https://hviedjw0hb.execute-api.us-east-1.amazonaws.com/production/instance-status', payload);
          return JSON.parse(response.data.body);
        } catch (error) {
          console.error(`Error fetching ${type} instance status:`, error);
          return null;
        }
      };

      const applicationData = await fetchInstanceStatus('Application');
      const simulatorData = await fetchInstanceStatus('Simulator');
      const metalData = await fetchInstanceStatus('Metal');



      setInterval(() => {
        // logMessage("Updated status fetched!");

        const applicationData = fetchInstanceStatus('Application');
        const simulatorData = fetchInstanceStatus('Simulator');
        const metalData = fetchInstanceStatus('Metal');
      }, 15000);


      if (applicationData) setApplicationInstances(applicationData.instances);
      if (simulatorData) setSimulatorInstances(simulatorData.instances);
      if (metalData) setMetalInstances(metalData.instances);
    };

    fetchInstanceDetails();
  }, []);

  // Devices that haven't received data should be considered offline
  useEffect(() => {
    const interval = setInterval(() => {
      Object.keys(statusData).forEach((hardware) => {
        if (!statusData[hardware].timestamp) {
          setDeviceStatuses((prevStatuses) => ({
            ...prevStatuses,
            [hardware]: false // Mark device as offline if no data received
          }));
        }
      });
    }, 10000); // Check every 10 seconds for devices that are still offline
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [statusData]);


  if (isLoading) {
    return <BouncingLoader />;
  }
  
  if (!isAdmin) {
    return <div>Not an admin user!</div>;
  }
  
  if (error) {
    return <div>Error: {error}</div>;
  }
  
  return (
    <div id="app" className="background">
      <Sidebar />
      <div className="container">
        <div className="contentContainer">
          <TopBar />
          <div className="app-container">
            <div className="main-content">
              <h1 className="health-check-heading">Admin Console</h1>
              <h2 className='select-vehicle-heading'>Select your vehicle below:</h2>
              <select value={selectedVIN} onChange={handleVINChange}>
                <option value="1KGEJ6605A5A354BA">1KGEJ6605A5A354BA - India</option>
                <option value="1KGEJ6605D0F11787">1KGEJ6605D0F11787 - US</option>
              </select>
              <button onClick={() => setShowPopup(true)} className="hw-assist-button">
                H/W Assist Tips
              </button>
              {showPopup && (
                <div className="hw-assist-popup">
                  <h2>Troubleshooting Steps</h2>
                  <ul>
                    <li>Step 1: Check device power</li>
                    <li>Step 2: Restart the service</li>
                    <li>Step 3: Verify the connections</li>
                  </ul>
                  <button onClick={() => setShowPopup(false)} className="close-popup-button">Close</button>
                </div>
              )}
              {isLoading ? (
                <BouncingLoader />
              ) : (
                <div className="status-grid">
                  {Object.keys(statusData).map((hardware) => (
                    <StatusOverview
                      key={hardware}
                      hardwareData={{
                        hardware, // Device name
                        status: statusData[hardware].status,
                        timestamp: statusData[hardware].timestamp,
                      }}
                      onRestart={(device, serviceName) => handleRestart(device, serviceName)} // Pass the correct handler here
                      isLoading={isLoading}
                      isOnline={deviceStatuses[hardware] || false} // Use device status from the state
                      selectedVIN={selectedVIN}
                      customHeading={hardware.toUpperCase()} // Set heading to uppercase
                      onToggleState={(payload) => handleToggleState(payload)}  // Handle toggle state for Voice Assist
                    />
                  ))}

                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <h2 className="control-instances">Control Instances</h2>
                  </div>
                  <div></div>
                  <div className="control-buttons">

                    <button className="start-button" onClick={() => controlInstances('Application', 'Start')}>Start Application</button>
                    <button className="stop-button" onClick={() => controlInstances('Application', 'Stop')}>Stop Application</button>

                    <button className="start-button" onClick={() => controlInstances('Simulator', 'Start')}>Start Simulator</button>
                    <button className="stop-button" onClick={() => controlInstances('Simulator', 'Stop')}>Stop Simulator</button>

                    <button className="start-button" onClick={() => controlInstances('Metal', 'Start')}>Start Metal</button>
                    <button className="stop-button" onClick={() => controlInstances('Metal', 'Stop')}>Stop Metal</button>



                    <div className="instance-details">
                      <div class="typeNames">Application Instances Status</div>
                      {Object.entries(applicationInstances).map(([instance, status]) => (
                        <div key={instance}>{instance}: {status}</div>
                      ))}
                    </div>

                    <div className="instance-details">
                      <div class="typeNames">Simulator Instances Status</div>
                      {Object.entries(simulatorInstances).map(([instance, status]) => (
                        <div key={instance}>{instance}: {status}</div>
                      ))}
                    </div>

                    <div className="instance-details">
                      <div class="typeNames">Metal Instances Status</div>
                      {Object.entries(metalInstances).map(([instance, status]) => (
                        <div key={instance}>{instance}: {status}</div>
                      ))}
                    </div>


                  </div>
                  {/* New div for file upload and text boxes */}


                  {/* start */}
                  <div className="file-upload-container">
                    <h2 className="file-upload-heading">Voice Assistant Settings</h2>
                    {(isUploading || isResetting) && (
                      <div className="loader-container">
                        <BouncingLoader />
                      </div>
                    )}
                    {!isUploading && !isResetting && (
                      <>
                        <div className="file-upload-content">
                          <textarea
                            value={textBoxValue1}
                            onChange={handleTextBoxChange1}
                            placeholder="Enter text here"
                            className="text-box"
                          />
                          <button onClick={handleOEMSubmit} className="submit-button">Update OEM</button>
                        </div>
                        <div className="file-upload-content">
                          <textarea
                            value={textBoxValue2}
                            onChange={handleTextBoxChange2}
                            placeholder="Enter text here"
                            className="text-box"
                          />
                          <button onClick={handleDiagnosticSubmit} className="submit-button">Update Diagnostic</button>
                        </div>
                        <div className="file-upload-content">
                          <label htmlFor="file-upload" className="file-input-label">
                            {'Choose File'}
                          </label>
                          <input
                            id="file-upload"
                            type="file"
                            accept="application/pdf"
                            onChange={handleFileChange}
                            className="file-input"
                            style={{ display: 'none' }}
                          />
                          <button onClick={handleFileUpload} className="upload-button">Upload PDF</button>
                        </div>
                        {fileMessage && <p className="file-message">{fileMessage}</p>}
                        <button onClick={handleReset} className="reset-button">Reset OEM Manual</button>
                      </>
                    )}
                  </div>


                  {/* End */}
                </div>
              )}
            </div>
            <RefreshFAB onClick={handleRefresh} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
