import React, { useState, useEffect } from 'react';
import './StatusOverview.css';

function StatusOverview({ hardwareData, onRestart, isLoading, isOnline, customHeading, selectedVIN, onToggleState }) {
  const [serviceStatus, setServiceStatus] = useState(hardwareData.status);
  const [voiceAssistState, setVoiceAssistState] = useState('offline');  // Default state for voice_assist is 'offline'

  useEffect(() => {
    // Update service status if it changes
    if (JSON.stringify(serviceStatus) !== JSON.stringify(hardwareData.status)) {
      setServiceStatus(hardwareData.status);
    }

    // If the hardware is ORIN, check the state of voice_assist and voice_assist_online
    if (hardwareData.hardware === 'orin') {
      const voiceAssistStatus = hardwareData.status.voice_assist;
      const voiceAssistOnlineStatus = hardwareData.status.voice_assist_online;

      if (voiceAssistOnlineStatus === 'active') {
        setVoiceAssistState('online');  // Set as 'ON' when voice_assist_online is active
      } else if (voiceAssistStatus === 'active') {
        setVoiceAssistState('offline'); // Set as 'OFF' when only voice_assist is active
      } else {
        setVoiceAssistState('offline'); // Keep as 'OFF' if both are inactive
      }
    }
  }, [hardwareData.status]);

  const handleRestart = (serviceName) => {
    console.log(`Restarting service: ${serviceName} on device: ${hardwareData.hardware}`);

    // Toggle status of the service on the UI (optional, adjust as per your real logic)
    const newStatus = serviceStatus[serviceName] === 'active' ? 'inactive' : 'active';
    
    setServiceStatus({
      ...serviceStatus,
      [serviceName]: newStatus,
    });

    // Call the parent function to trigger the restart, passing the correct service name
    onRestart(hardwareData.hardware, serviceName); // Ensure the actual service name is passed here
  };

  const handleVoiceAssistStateToggle = (newState) => {
    // Send the state toggle command to the backend
    const payload = {
      VIN: selectedVIN,
      device: 'orin',
      service: 'voice_assist',
      state: newState,
      command: "switch"
    };

    onToggleState(payload);  // Call the callback function to send the toggle command to the backend
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return 'N/A';
    return new Date(timestamp).toLocaleString(); // Format timestamp as per your requirement
  };

  return (
    <div className="status-component">
      <h2>{customHeading}</h2> {/* Render the custom heading for the hardware */}

      {/* Display online/offline status in the top-right corner */}
      <div className="status-indicator">
        {isOnline ? 'Online' : 'Offline'}
      </div>

      <ul className="status-list">
        {/* Render all service statuses dynamically */}
        {Object.entries(serviceStatus).map(([serviceName, status], index) => (
          <li key={index} className={status === 'active' ? 'online' : 'offline'}>
            {serviceName}: {status === 'active' ? 'Active' : 'Inactive'}{' '}
            {status === 'active' ? '✓' : '✘'}

            {/* Conditionally render the restart button */}
            {hardwareData.hardware !== 'goldbox' && (
              <button
                className="restart-button"
                onClick={() => handleRestart(serviceName)}
                disabled={isLoading}
              >
                Restart
              </button>
            )}
          </li>
        ))}
      </ul>

      {/* For ORIN hardware and voice_assist service, render the toggle switch */}
      {hardwareData.hardware === 'orin' && (
        <div className="voice-assist-toggle">
          <label>{`Voice Assist: ${voiceAssistState === 'online' ? 'Online' : 'Offline'}`}</label>
          <label className="switch">
            <input
              type="checkbox"
              checked={voiceAssistState === 'online'}
              onChange={() => handleVoiceAssistStateToggle(voiceAssistState === 'online' ? 'offline' : 'online')}
            />
            <span className="slider round"></span>
          </label>
        </div>
      )}

      <small>Last updated: {formatTimestamp(hardwareData.timestamp)}</small>
    </div>
  );
}

export default StatusOverview;
