import React from 'react';
import Sidebar from "../../components/SideNavBar/SideNavBar";
import TopBar from "../../components/TopBar/TopBar";
import './CodebeamerReportDownloader.css';
import CodeBeamerFunctional from "../../images/codebeamer-functional.jpg";
import CodeBeamerSystem from "../../images/codebeamer-system.png";

const CodebeamerReportDownloader = () => {

  const handleButtonClick = (url) => {
    window.open(url, '_blank');  // Open the link in a new tab
  };

  return (
    <div id="app" className="background codebeamer-report-downloader">
      <Sidebar />
      <div className="container">
        <div className="contentContainer">
          <TopBar />
          <h1>Codebeamer Test Reports</h1>
          <div id="main-content">
            <div id="image-container">
              {/* Stack images vertically */}
              <img
                src={CodeBeamerFunctional} // Update with correct path
                alt="Webpage Screenshot 1"
                className="screenshot"
              />
              <img
                src={CodeBeamerSystem} // Update with correct path
                alt="Webpage Screenshot 2"
                className="screenshot"
              />
            </div>
            <div id="buttons-container">
              {/* Buttons to the right */}
              <button className="button" onClick={() => handleButtonClick('http://sdv-codebeamer-lb-1403250088.us-east-1.elb.amazonaws.com/cb/tracker/7587')}>
                Access Functional Test Cases
              </button>
              <button className="button" onClick={() => handleButtonClick('http://sdv-codebeamer-lb-1403250088.us-east-1.elb.amazonaws.com/cb/tracker/6188')}>
                Access System Test Cases
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CodebeamerReportDownloader;
