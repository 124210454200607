import "./App.css";
import React, { useState } from "react";
import { ReactComponent as LandingCar } from "./images/landing_car.svg";
import { ReactComponent as LandingPageLine } from "./images/landing_page_line.svg";
import { ReactComponent as AuthPrivacy } from "./images/AuthPrivacy.svg";
import { ReactComponent as AuthLine } from "./images/Authline.svg";
import DeloitteImg from "./images/Logo.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Alert, Snackbar } from "@mui/material";
import { Button } from "antd";
function App() {
  const navigate = useNavigate();
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [showSignInPOPUp, setShowSignInPOP] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);

  function handleSignInClick() {
    setShowSignInPOP(false);
    // setShowLoginForm(true);
    // //sso implementation
    const incidentNumber="WO0010020"
    console.log("Inside SSO Authentication");
    
    window.location.href="https://sdvexperience-backend.deloitte.com/login/azuread-oauth2/";
   
    // navigate("/persona/chapter-one", {
    //  state: { activemenu: "ch1", submenu: "" },
    // });
  }
  
  const handleEmailChange = (event) => {
    setEmail(event.target.value); // Update email state with user input
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value); // Update email state with user input
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };


  return (
    <div className="App">
      <Snackbar
        open={alertOpen}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={"error"} color="error">
          Login Unsuccessful. Please try again
        </Alert>
      </Snackbar>
    {/* <button style={{
                    position: 'absolute',  // Positioning at the top-left
                    top: '20px',           // Distance from the top of the page
                    left: '20px',          // Distance from the left of the page
                    backgroundColor: '#007bff', // Button color
                    color: 'white',        // Text color
                    padding: '10px 20px',  // Padding inside the button
                    border: 'none',        // Remove default border
                    borderRadius: '5px',   // Rounded corners
                    cursor: 'pointer'      // Cursor changes to pointer on hover
                }} onClick={createIncident}>createIncident</button> */}
      <LandingPageLine className="Bar" />
      <LandingCar className="Car" />
     
      {showSignInPOPUp && (
        <div
          style={{
            position: "absolute",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            top: 0,
            right: "27vh",
          }}
        >
          <div className="SignPopUp">
            <div style={{ marginLeft: "-15px" }}>
              <img src={DeloitteImg} alt="My Image" />
            </div>
            <div className="sdv_exp">SDV EXPERIENCE</div>
            <div className="sdv_exp_subtext">
              Driving into the Future: A ‘Car to Cloud’ Journey with Software
              Defined Vehicles
            </div>
            <button className="signin-button" onClick={handleSignInClick}>
              Sign In <span className="icon">&gt;</span>
            </button>
          </div>
        </div>
      )}
      
    </div>
  );
}

export default App;
