import React, { useEffect, useState } from 'react';
import Sidebar from "../../components/SideNavBar/SideNavBar";
import TopBar from "../../components/TopBar/TopBar";
import './DMSReportDownloader.css';
import Redhat from "../../images/redhat_pipelinescreenshot.png";
import { Link} from "react-router-dom";

const ReportDownloader = () => {
  const [reports, setReports] = useState([]);

  useEffect(() => {
    // Fetch the metadata file containing the list of reports
    fetch('/DMS_test_reports/testReportsMetadata.json')
      .then(response => response.json())
      .then(data => {
        setReports(data.reports);
      })
      .catch(err => {
        console.error('Error fetching metadata:', err);
        setReports([]);  // Optional: You can handle an empty state in case of failure
      });
  }, []);

  return (
    <div id="app" className="background report-downloader">
      <Sidebar />
      <div className="container">
        <div className="contentContainer">
          <TopBar />
          <h1>Download DMS Test Reports</h1>
          <div id="main-content">
            <div id="image-container">
              {/* Add image (screenshot of webpage) */}
              <a 
                href="https://console-openshift-console.apps.rosa.sdv-hub.4taw.p3.openshiftapps.com/k8s/ns/sdv-pipeline/tekton.dev~v1~PipelineRun/sdv-pipeline-p6omy5/" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                <img src={Redhat} alt="Red Hat Pipeline Screenshot" className="screenshot"/>
              </a>
            </div>
            <div id="report-links">
            {/* <Link
                      style={{
                        alignItems: "end",
                        textDecoration: "none",
                        color: "white",
                        fontSize:"14px",fontFamily:"Open Sans",fontWeight:"400"
                      }}
                      to={
                        "https://www.ti.com/lit/ug/spruj62/spruj62.pdf?ts=1714913529791"
                      }
                      target="_blank"
                    >
                      TDA4 EVM User's Guide
                    </Link> */}
                    <a href="https://eh-de.atlassian.net/browse/DDMS-181" target="_blank" rel="noreferrer" style={{ textDecoration: 'underline', color: 'white' ,backgroundColor:"black"}}><p style={{  color: 'white',backgroundColor:"" }}>Go To Jira</p></a>
              {reports.length === 0 ? (
                <p>No reports available for download.</p>
              ) : (
                reports.map((report, index) => (
                  <div key={index}>
                    <a href={`/DMS_test_reports/${report}`} download>
                      Download {report}
                    </a>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportDownloader;
