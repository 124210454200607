import React, { useState, useEffect } from "react";
import { useNavigate} from "react-router-dom";
import axios from "axios";
import "./alertDetails.css";
import "./IconSlider.css"; // Import CSS file for styling
import "./Chapterone.css";
import Sidebar from "../../components/SideNavBar/SideNavBar";
import TopBar from "../../components/TopBar/TopBar";
import Graph1 from "../../images/SummaryChartOne.svg";
import Graph2 from "../../images/SummaryChartTwo.svg";
import Graph3 from "../../images/SummaryChartThree.svg";
import Graph4 from "../../images/SummaryChartFour.svg";
import Card from "../../components/Card/SummaryCard";
import TxtOne from "../../images/SummaryTextOne.svg";
import TxtTwo from "../../images/SummaryTextTwo.svg";
import TxtThree from "../../images/SummaryTextThree.svg";
import ChatBot from "../../templates/ChatBotQuality";
import {ReactComponent as ChatBotLogo} from "../../images/ChatBotButton.svg";
import Cookies from 'js-cookie';

const ChapterOne = () => {
  const navigate = useNavigate();
  const [isChatBotOpen, setChatBotOpen] = useState(false);
  const [classifiedClaims, setClassifiedClaims] = useState([]);
  //sso
  const [userDetails, setUserDetails] = useState(null);
  const [userName, setUserName] = useState('Dummay Name');
  const [photoUrl, setPhotoUrl] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {

    const csrfToken = Cookies.get('csrftoken');
    const sessionId = Cookies.get('sessionid');
    console.log("token",csrfToken)
    console.log("session",sessionId)
    const fetchUserData = async () => {
        try {
            
            const response = await axios.get('https://Telematics-Backend-895771443.us-east-1.elb.amazonaws.com/sdv/getName/', {
                headers: {
                'X-CSRFToken': csrfToken,
                },
                withCredentials: true, // if you need to send cookies with the request
                });
                    console.log("Response Data",response.data['username'])
                    setUserDetails(response.data);
                    setUserName(response.data['username'])

        } catch (error) {
            setError(error.message);
        }
    };

    const fetchProfilePhoto = async () => {
      try {
          const response = await axios.get('https://127.0.0.1:8000/getProfilePhoto/', {
              responseType: 'blob', // Important for getting the image as a blob
              withCredentials: true, // if your backend requires authentication cookies
          });

          const url = URL.createObjectURL(response.data);
          setPhotoUrl(url);
      } catch (error) {
          setError('Failed to load profile photo');
          console.error('There was an error fetching the profile photo:', error);
      }
  };
  
    // fetchProfilePhoto();
    handleDataArray(); // Fetch data when the component mounts or 'escalateAlert' changes
    // fetchUserData();

  },[]);
  

  const handleDataArray = async () => {
    await getClassifiedClaims();
  };

  const openClaimAnalysis = (issueTitle,priorityLevel,year,model,vin,riskscore,component) => {
    console.log("Selected Issue",issueTitle)
    const description=`
        Model: ${model}
        Model Year: ${year}
        Component: ${component}
        VIN#: ${vin}
        Risk Score: ${riskscore}
        `
    console.log("Combined DAta ",description)
    // createIncidents(issueTitle,priorityLevel,description)
     navigate("/claim-analysis",{state:{ activemenu: "ch1", submenu: "" }});
    
  };

  const HnadleChatBotClicked = async(e) => {
    setChatBotOpen(true);    
  };
  const handleClosePopup = () => {
    setChatBotOpen(false);
};
  const getClassifiedClaims = async () => {
    let classifiedClaimURL =
      "https://dp8bxyeczqirv.cloudfront.net/classified_claims.json";
    await axios
      .get(classifiedClaimURL)
      .then((data) => {
        const result = data.data;
        setClassifiedClaims(result);
      })
      .catch((error) => {});
  };


  const YourContentComponent = () => {
    return (
      <div id="app" className="background">
   <Sidebar></Sidebar>      
        <div className="container">
          <div className="contentContainer">
           
              <TopBar></TopBar>
              
            <main className="main">
              <div className="chapterTitle">SDV Quality</div>
                <div className="chapterInfo">
                  Transforming quality management with intelligence, ensuring
                  excellence through data-driven insights and continuous
                  improvement.
                </div>
              <div className="claimSummarygraph">
                <div
                  className="claimSummaryTitle">
                  Claim Summary
                </div>
                <div
                 className="cardBackground"
                >
                 <Card
                 summaryTitle={"Production Date"}
                 logo={Graph1}
                 summarySubTitle={TxtOne}
                 paddingValue={"10px"}
                 ></Card>
                 <Card summaryTitle={"Model"}
                 logo={Graph2}
                 summarySubTitle={TxtTwo}
                 paddingValue={"16px"}
                 ></Card>
                 <Card summaryTitle={"Software Version"}
                  paddingValue={"16px"}
                  summarySubTitle={TxtThree}
                 logo={Graph3}></Card>
                 <Card summaryTitle={"Distribution Country"}
                    paddingValue={"0px"}
                 logo={Graph4}></Card>
                </div>
              </div>
              <div className="alertSummary">
                <div className="claimListBackground">
                  Claim List
                  </div>
                  <div
                  className="claimListTableGap"
                  >
                {classifiedClaims != null  ? (
                  <div
                    id="alert"
                    style={{ fontFamily: "Open Sans", width: "99%"}}
                  >
                    <div
                      className="claimListMargin"
                    >
                      <div
                        className="claimID"
                      >
                        ID
                      </div>
                      <div
                        className="claimModel"
                      >
                        Model
                      </div>
                      <div
                      className="alertPriority"
                      >
                        Alert Priority
                      </div>
                      <div
                       className="modelYear"
                      >
                        Model Year
                      </div>
                      <div
                       className="component"
                      >
                        Component
                      </div>
                      <div
                       className="issue"
                      >
                        Issue
                      </div>
                      <div
                       className="vin"
                      >
                        VIN#
                      </div>
                      <div
                       className="riskScore"
                      >
                        Risk Score
                      </div>
                    </div>

                    <div
                      style={{maxHeight: "30vh",
                      overflowX: "hidden",
                      gap: "3px",
                      width:"100%",
                      WebkitOverflowScrolling: "touch"}}
                    >
                      {classifiedClaims.length > 0 ? (
                        classifiedClaims
                          .map((alert, index) => (
                            <div
                              key={index}
                              className="claimItem"
                              // onClick={openClaimAnalysis}
                              onClick={() => openClaimAnalysis(alert.claims[0].comment,alert.claims[0].priority,
                                alert.claims[0].mfgyear,alert.claims[0].model,alert.claims[0].vin,alert.claims[0].riskscore,alert.name
                              )}
                            >
                              <div
                               className="itemID" 
                              >
                                {alert.claims[0].id}
                              </div>
                              <div
                                 className="itemModel"
                              >
                                {alert.claims[0].model}
                              </div>
                              <div
                                 className="itemPriority"
                              >
                                {alert.claims[0].priority}
                              </div>
                              <div
                                className="itemYear"
                              >
                                {alert.claims[0].mfgyear}
                              </div>
                              <div
                                className="itemAlert"
                              >
                                {alert.name}
                              </div>
                              <div
                                 className="itemComment"
                              >
                                {alert.claims[0].comment}
                              </div>
                              <div
                                className="itemVin"
                              >
                                {alert.claims[0].vin}
                              </div>
                              <div
                                className="itemRiskScore"
                              >
                                {alert.claims[0].riskscore}
                              </div>
                            </div>
                          ))
                      
                          ) 
                      : (
                        <p
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Loading Data
                        </p>
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
               </div>
              </div>
              <ChatBotLogo className="bottomRightElement" onClick={HnadleChatBotClicked}></ChatBotLogo> 
            </main>
          </div>
        </div>
        {isChatBotOpen && (
        <div className="chatWindow">
          <ChatBot onCloseClick={handleClosePopup} />
        </div>
      )}
      </div>
    );
  };
  return (
      <YourContentComponent />
  );
  
};

export default ChapterOne;

